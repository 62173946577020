define("discourse/plugins/email-all/discourse/templates/admin/plugins-email-all", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='email-template'>
    <label>{{i18n "admin.customize.email_templates.subject"}}</label>
    {{input value=subject}}
    <br>
  
    <label>{{i18n "admin.customize.email_templates.body"}}</label>
    {{d-editor value=body}}
  
    <button {{action 'send'}} class='btn btn-primary'>Send to All</button>
  </div>
  
  */
  {
    "id": "Bj5C6uFC",
    "block": "[[[10,0],[14,0,\"email-template\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,2],[\"admin.customize.email_templates.subject\"],null]],[13],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"value\"],[[30,0,[\"subject\"]]]]]],[1,\"\\n  \"],[10,\"br\"],[12],[13],[1,\"\\n\\n  \"],[10,\"label\"],[12],[1,[28,[35,2],[\"admin.customize.email_templates.body\"],null]],[13],[1,\"\\n  \"],[1,[28,[35,5],null,[[\"value\"],[[30,0,[\"body\"]]]]]],[1,\"\\n\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,7],[[30,0],\"send\"],null],[12],[1,\"Send to All\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `subject` property path was used in the `discourse/plugins/email-all/discourse/templates/admin/plugins-email-all.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.subject}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `body` property path was used in the `discourse/plugins/email-all/discourse/templates/admin/plugins-email-all.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.body}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"div\",\"label\",\"i18n\",\"input\",\"br\",\"d-editor\",\"button\",\"action\"]]",
    "moduleName": "discourse/plugins/email-all/discourse/templates/admin/plugins-email-all.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});